import {time} from './helpers';
import appCache from './appCache';


export default class appCacheCordova extends appCache {
    constructor() {
        super();
        const cordova = window.cordova;
        this.dataDir = 'data';
        this.tokenFile = 'token.json';
        this.tipsFile = 'tips.json';
        this.badgeFile = 'badge.json';
        this.boardingFile = 'boarding_files.json';
        this.notificationFile = 'notification.json';
        this.startURLFile = 'url.json';
        this.fs = cordova.file.documentsDirectory || cordova.file.dataDirectory;
        this.cache = {};
    }

    static doNothing() {}

    getToken(callback) {
        try {
            this.readFile(this.tokenFile, json => {
                console.log(`${this.tokenFile} contains ${json}`);
                callback(JSON.parse(json));
            }, () => callback());
        } catch (err) {
            console.error(`Can't read token ${err}`);
            callback();
        }
    }

    removeToken(callback, fail) {
        this.removeFile(this.tokenFile, callback, fail);
    }

    getNotification(callback, fail = appCacheCordova.doNothing) {
        this.readFile(this.notificationFile, json => {
            if (!json) fail();
            else callback(JSON.parse(json));
        }, fail);
    }

    getStartURL(callback, fail = appCacheCordova.doNothing) {
        this.readFile(this.startURLFile, json => {
            let url = null;
            try {
                url = JSON.parse(json).url;
            } catch (e) {
                fail();
                return;
            }
            callback(url);
        }, fail);
    }

    clearNotification() {
        this.writeFile(this.notificationFile, '');
    }

    saveStartURL(url) {
        this.writeFile(this.startURLFile, JSON.stringify({url}));
    }

    setBadge(count, success, fail) {
        this.writeFile(
            this.badgeFile,
            JSON.stringify({number: count}),
            success,
            fail
        );
    }

    erase(callback, fail) {
        console.log('Erasing whole cache');
        this._resolveFS(root => {
            root.getDirectory(this.dataDir, {}, dir => {
                dir.removeRecursively(callback, fail);
            }, fail);
        }, fail);
    }

    get(uri, callback, fail = appCacheCordova.doNothing) {
        if (!callback) {
            console.error('appCacheCordova.get called without callback');
            return;
        }
        this.readFile(this._getFileName(uri), json => {
            let obj;
            try {
                obj = JSON.parse(json);
            } catch (e) {
                console.error(`Can't parse JSON for url ${uri}: ${json}`);
                fail();
                return;
            }
            callback(obj);
        }, fail);
    }

    set(
        uri, value, callback = appCacheCordova.doNothing,
        fail = appCacheCordova.doNothing
    ) {
        let json;
        try {
            json = JSON.stringify(value);
        } catch (e) {
            console.error(`Can't stringify obj to JSON for url ${uri}: `, e);
            fail();
            return;
        }
        this.writeFile(this._getFileName(uri), json, data => {
            this.timestamps[uri] = time();
            callback(data);
        }, fail);
    }

    removeFile(
        fileName, callback = appCacheCordova.doNothing,
        fail = appCacheCordova.doNothing
    ) {
        console.log(`appCacheCordova.writeFile ${fileName}`);
        this._getFileEntry(fileName, true, fileEntry => {
            fileEntry.remove(callback, fail);
        }, fail);
    }

    writeFile(
        fileName, data, callback = appCacheCordova.doNothing,
        fail = appCacheCordova.doNothing
    ) {
        console.log(`appCacheCordova.writeFile ${fileName}`);
        this._getFileEntry(fileName, true, fileEntry => {
            appCacheCordova._writeFile(fileEntry, data, callback, fail);
        }, fail);
    }

    readFile(fileName, callback, fail = appCacheCordova.doNothing) {
        console.log(`appCacheCordova.readFile ${fileName}`);
        this._getFileEntry(fileName, false, fileEntry => {
            appCacheCordova._readFile(fileEntry, callback, fail);
        }, fail);
    }

    _getFileEntry(fileName, create, callback, fail) {
        let index = 0;
        let dir = this.dir;
        let file_name = fileName;
        const path = file_name.split(/\//);
        const _getFile = () => {
            dir.getFile(file_name, {create, exclusive: false}, callback, fail);
        };
        const _getNextDir = () => {
            dir.getDirectory(path[index], {create}, subDir => {
                index += 1;
                dir = subDir;
                if (index >= path.length) {
                    _getFile();
                } else {
                    _getNextDir();
                }
            }, fail);
        };
        this._resolveFS(resolvedDir => {
            dir = resolvedDir;
            if (create) {
                file_name = path.pop();
                if (!path.length) {
                    _getFile();
                } else _getNextDir();
            } else _getFile();
        }, fail);
    }

    _resolveFS(callback, fail) {
        if (!this.dir) {
            // console.log(`appCacheCordova.resolveLocalFileSystemURL ${this.fs}`)
            window.resolveLocalFileSystemURL(this.fs, dirEntry => {
                this.dir = dirEntry;
                callback(this.dir);
            }, fail);
        } else {
            callback(this.dir);
        }
    }

    _getFileName(uri) {
        let [fullname, params] = uri.split('?');
        let [, path, filename] = fullname.match(/^(.*?)\/?([^/]*)$/);
        if (!filename) filename = 'index.json';
        if (params) {
            params = params.replace(/\W+/g, '_');
            const [, name, ext] = filename.match(/^(.*?)(\.[^.]+)?$/);
            filename = `${name}_${params}${ext}`;
        }
        return `${this.dataDir}/${path}/${filename}`;
    }

    static _writeFile(fileEntry, dataObj, callback, fail) {
        fileEntry.createWriter(fileWriter => {
            fileWriter.onwriteend = callback;
            fileWriter.onerror = fail;
            fileWriter.write(dataObj);
        }, fail);
    }

    static _readFile(fileEntry, callback, fail) {
        fileEntry.file(file => {
            const reader = new FileReader();
            reader.onerror = fail;
            reader.onloadend = () => callback(reader.result);
            reader.readAsText(file);
        }, fail);
    }

    getTips(callback) {
        this.readFile(this.tipsFile, json => {
            callback(JSON.parse(json) || {});
        }, () => callback({}));
    }

    setTip(tip, value) {
        this.getTips(tips => {
            tips[tip] = value;
            this.writeFile(this.tipsFile, JSON.stringify(tips));
        });
    }

    isBPUniq(checkin_id, callback) {
        let bp = {};
        if (!checkin_id) {
            console.error('Can\'t check is BP uniq no checkin_id');
            return;
        }
        const saveBP = () => {
            bp[checkin_id] = true;
            this.writeFile(this.boardingFile, JSON.stringify(bp));
        };
        this.readFile(this.boardingFile, json => {
            bp = JSON.parse(json);
            const result = bp[checkin_id];
            saveBP();
            if (!result) callback();
        }, () => {
            saveBP();
            callback();
        });
    }
}
