import {
    addClickEvent,
    addEvent,
    deepcopy,
    getScrollTop,
    is_xs,
    setScrollTop,
    toggleClass
} from './helpers';
import user from './views/user/user';
// #if process.env.MOBILE_APP
import appCache from './appCacheCordova'
import platform from './platformCordova'
// #endif
// #if !process.env.MOBILE_APP
// import appCache from './appCache'
// import platform from './platformWeb'
// #endif


export default class app {
    constructor(start_uri, settings, lang) {
        this.configure(start_uri, settings, lang);
        this.init(start_uri);
    }

    init(start_uri) {
        this.bindLinks();
        this.bindAddButton();
        this.bindPopstate();
        this.bindKeyEvents();
        if (is_xs()) {
            this.bindPullToRefresh();
            this.bindPullBack();
        } else {
            this.bindClickToRefresh();
        }

        this.renderIcons();

        this.platform.init();
        if (!this.getObject('chats/')) {
            this.chat = this.initObject('chats/', 'chat');
            if (this.chat) this.chat.get(false, () => {
            });
        }
    }

    configure(start_uri, settings, lang) {
        this.start_uri = start_uri;
        this.objects = {};
        this.viewClasses = {};
        settings.classRoutes.forEach((element) => {
            this.viewClasses[element.className] = element.constructor;
        });
        this.settings = Object.assign({
            xhr_timeout: 15000,
            token_header: 'X-Token',
            admin_token_header: 'X-Admin-Token',
            root: window.document.body,
            scroll_top_shift: 10,
            scroll_bottom_shift: 10,
            transitions_base_time: 200,
        }, settings);
        this.settings.landingLang = `${this.settings.landing}${
            this.settings.lang === 'en' ? '' : this.settings.lang}`;
        this.root = this.settings.root;
        this.Sentry = settings.Sentry;
        this.Mustache = settings.Mustache;
        this.flatpickr = settings.flatpickr;
        this.subscriptions = {};
        this.default_headers = {
            'Content-Type': 'application/json;charset=UTF-8'
        };
        this.is_online = true;
        this.lang = lang;
        this.dataStorage = new appCache();
        if (this.settings.dev && this.settings.cache) {
            this.dataStorage.cache = this.settings.cache;
        }
        this.platform = new platform(this);

        const cssPrefix = this.settings.cssPrefix && this.settings.cssPrefix.prefix
            || '';
        this.icon_selector = `.${cssPrefix}icon`;
        this.dropdown_selector = `.${cssPrefix}dropdown`;
        ['token', 'admin_token'].forEach((type) => {
            if (this.settings[type]) {
                this[type] = this.settings[type];
                this.default_headers[this.settings[`${type}_header`]] = this[type];
            }
        });
        this.current_view = null;
        this.history = [];
    }

    toggleMainMenu(flag = true) {
        toggleClass(document.body, 'hidden-menu', !flag)
    }

    getUserData(callback, fail, force = false) {
        if (
            !force
            && this.settings.user_profile
            && this.settings.user_profile.billing
        ) {
            callback(this.settings.user_profile);
            return this.settings.user_profile;
        }

        if (!this.waitingForUserDataCallbacks) {
            this.waitingForUserDataCallbacks = []
        }
        if (!this.waitingForUserDataErrors) {
            this.waitingForUserDataErrors = []
        }
        this.waitingForUserDataCallbacks.push(callback);
        if (fail) {
            this.waitingForUserDataErrors.push(fail);
        }
        if (this.waitingForUserData) return;
        this.waitingForUserData = true;

        const userI = new user('user/', this, 'user');
        userI.get(force,
            () => {
                this.waitingForUserData = false;
                this.waitingForUserDataCallbacks.forEach(
                    code => code(this.settings.user_profile)
                );
                this.waitingForUserDataCallbacks = [];
                this.waitingForUserDataErrors = [];
                if (force) {
                    this.updateView('user_menu/');
                    this.updateView('user/menu/');
                    this.reRenderAll();
                }
            },
            status => {
                this.waitingForUserData = false;
                this.waitingForUserDataErrors.forEach(
                    code => code(userI, status)
                );
                this.waitingForUserDataCallbacks = [];
                this.waitingForUserDataErrors = []
            }
        );
    }

    reRenderAll() {
        Object.keys(this.objects).forEach(uri => {
            const page = this.objects[uri];
            this.reRender(uri);
        })
    }

    reRender(uri) {
        const view = this.objects[uri];
        if (!view) return;
        if (view == this.current_view) {
            view.render();
        } else {
            view.needReRender = true;
        }
        view.needReRender = true;
    }

    updateView(uri) {
        const view = this.objects[uri];
        if (!view) return;
        view.get(false, () => view.render());
    }

    prepareAlerts() {
        if (this.platform.name === 'cordova') {
            var iframe = document.createElement('iframe');
            iframe.setAttribute("src", 'data:text/plain,');
            iframe.classList.add('hidden');
            document.body.appendChild(iframe);
            setTimeout(
                () => document.body.removeChild(iframe),
                this.settings.transitions_base_time
            );
            return iframe.contentWindow;
        }
        return window;
    }

    alert(str) {
        return this.prepareAlerts().alert(str);
    }

    confirm(str) {
        return this.prepareAlerts().confirm(str);
    }

    localStorage(key, value) {
        let data;
        try {
            if (typeof (value) !== 'undefined') {
                data = typeof (value) === 'string'
                    ? value
                    : JSON.stringify(value);
                window.localStorage.setItem(key, data);
                return value;
            }
            data = window.localStorage.getItem(key);
            return data && JSON.parse(data);
        } catch (err) {
            window.console.error(err);
            if (!this.local_storage_store) this.local_storage_store = {};
            if (typeof (value) !== 'undefined') {
                this.local_storage_store[key] = value;
            }
            return this.local_storage_store[key];
        }
    }

    renderIcons(param_root) {
        const root = param_root || this.root;
        root.querySelectorAll(this.icon_selector).forEach((icon) => {
            const m = icon.className.match(/(icon-\S+)/);
            if (m && document.getElementById(m[1])) {
                icon.innerHTML = document.getElementById(m[1]).innerHTML;
            }
        });
    }

    getUriForNewInstanse(prefix) {
        let i = 0;
        let url = '';
        do {
            url = `${prefix}new${i > 0 ? `-${i}` : ''}/`;
            i += 1;
        }
        while (url in this.objects);
        return url;
    }

    getObject(uri) {
        const isRegex = typeof (uri) === 'object' && ('test' in uri);
        if (!isRegex && uri in this.objects) return this.objects[uri];
        const key = Object.keys(this.objects).find(objURI => {
            const obj = this.objects[objURI];
            return isRegex
                ? objURI.match(uri)
                : obj.wildcard_uri && uri.startsWith(obj.wildcard_uri);
        });
        return key ? this.objects[key] : null;
    }

    setObject(uri, obj) {
        this.objects[uri] = obj;
    }

    deleteObject(uri) {
        if (uri in this.objects) delete this.objects[uri];
    }

    clearObjects() {
        this.objects = {};
    }

    changeObjectURI(obj, new_uri) {
        if (this.objects[obj.uri]) {
            this.objects[new_uri] = obj;
            delete this.objects[obj.uri];
        }
        obj.uri = new_uri;
        if (obj.view) obj.view.dataset.uri = new_uri;
    }

    get(uri, className, data, callback, force) {
        uri = this.platform.get(uri);
        const obj = this.initObject(uri, className, data);
        if (!obj) {
            console.error(`Can\'t find object with uri ${uri}`);
            return;
        }
        if (obj.wildcard_uri) obj.uri = uri;
        if (!obj.is_initialized || force) {
            obj.is_initialized = true;
            return obj.get(force, () => obj.show(callback), callback);
        }
        obj.show(callback);
        return obj;
    }

    revalidateData(view) {
        const uri = view.endpoint_uri || view.uri;
        if (this.is_online && this.dataStorage.needRevalidate(uri)) {
            // starting revalidating after transitions finished
            setTimeout(() => {
                this.revalidating(true);
                view.get(true, () => {
                    this.revalidating(false);
                    view.render();
                    setScrollTop(view.scrollTop || 0);
                })
            }, this.settings.transitions_base_time * 2);
        }
    }

    subscribe(uri, callback) {
        if (!this.subscriptions[uri]) {
            this.subscriptions[uri] = [];
        }
        if (this.subscriptions[uri].indexOf(callback) < 0) {
            this.subscriptions[uri].push(callback);
        }
    }

    getSubscriptions(uri) {
        const subscriptions = [];
        Object.keys(this.subscriptions).forEach((u) => {
            const re = RegExp(`^${u}$`);
            if (uri.match(re)) {
                this.subscriptions[u].forEach(callback => subscriptions.push(callback));
            }
        });
        return subscriptions;
    }

    getClassByName(className) {
        return this.viewClasses[className];
    }

    getClassByRoute(uri) {
        let uriClass = null;
        let matchLength = 0;
        this.settings.classRoutes.forEach((element) => {
            if (!element.pattern) return false;
            const re = new RegExp(`^${element.pattern}`);
            const match = uri.match(re);
            if (match && (match[0].length > matchLength || !matchLength)) {
                uriClass = element.constructor;
                matchLength = match[0].length;
            }
        });

        // trying first url part
        if (!uriClass) {
            const className = uri.split('/')[0];
            return this.getClassByName(className);
        }

        return uriClass;
    }

    initObject(uri, className, data, update_cache) {
        let obj = this.getObject(uri);
        if (obj && !update_cache) return obj;
        const filtered_uri = uri.replace(/^(?:https?:\/\/[^\/]+)?\//, '');
        const ViewClass = className
            ? this.getClassByName(className)
            : this.getClassByRoute(filtered_uri);
        if (!ViewClass) {
            return this.error({
                title: this.lang.classNotDefined,
                message: `${this.lang.classNotDefined} ${uri}`,
            });
        }
        obj = new ViewClass(filtered_uri, this, className, data || {});
        this.setObject(obj.wildcard_uri || obj.uri, obj);
        return obj;
    }

    bindLinks(param_root) {
        const root = param_root || this.root;
        const that = this;
        addClickEvent(root, '[data-bind="back"]', event => {
            if (this.history_back()) {
                event.preventDefault();
                event.stopImmediatePropagation();
            }
        });
        addClickEvent(root, '[data-bind="change-lang"]', function () {
            const lang = this.dataset.lang;
            that.platform.changeLang(lang);
        });
        addClickEvent(root, 'a', function (event) {
            const href = this.getAttribute('href');
            if (!this.dataset.toggle && href && href.match(/^#/)) {
                event.preventDefault();
                const url = href.replace(/^#/, '');
                if (url) {
                    that.get(
                        url, this.dataset.type, null, null, this.dataset.forceUpdate
                    );
                }
            }
        });
        this.bindLinksCollapse(root);
        this.bindLinksModals(root);
        if (this.dropdown_selector) this.bindLinksDropdown(root);
        this.bindTooltips(root);
    }

    showToolTip(event) {
        const tooltip = this.root.querySelector('[data-bind="tooltip"]');
        if (!tooltip) {
            return
        }
        const tooltip_inner = tooltip.querySelector('[data-bind="tooltip-inner"]');
        if (tooltip.dataset.timeout) {
            clearTimeout(parseInt(tooltip.dataset.timeout));
            tooltip.dataset.timeout = '';
        }
        event.stopPropagation();
        const target = event.target.closest('[data-toggle="tooltip"]')
            || event.target;
        if (!target) {
            return
        }
        if (target.getAttribute('title')) {
            target.dataset.title = target.getAttribute('title');
            target.removeAttribute('title');
        }
        if (target.dataset.content) {
            tooltip_inner.innerHTML = root
                .querySelector(target.dataset.content).innerHTML;
        } else if (target.dataset.title) {
            tooltip_inner.textContent = target.dataset.title;
        } else {
            return;
        }
        try {
            const rect = target.getBoundingClientRect();
            const trect = tooltip.getBoundingClientRect();
            const root_rect = this.root.getBoundingClientRect();
            tooltip.style.cssText = `
                left: ${rect.width / 2 - trect.width / 2 + rect.left - root_rect.left}px;
                top: ${rect.top + rect.height - root_rect.top}px;
            `;
            tooltip.classList.add('in');
        } catch (err) {
            console.error(err)
        }
    }

    bindTooltips(param_root) {
        const root = param_root || this.root;

        const tooltip = this.root.querySelector('[data-bind="tooltip"]');
        if (!tooltip) {
            return
        }

        let tooltips = root.querySelectorAll('[data-toggle="tooltip"]');
        if (!tooltips.length && root.dataset && root.dataset.tooltip) {
            tooltips = [root];
        }
        addEvent(tooltips, 'focus', event => this.showToolTip(event));
        addEvent(tooltips, 'blur', event => this.hideToolTip(event));
        addClickEvent(root, '.copy-mail', event => {
            event.preventDefault();
            const target = event.target.closest('.copy-mail');
            const selection = window.getSelection();
            const range = document.createRange();
            const div = document.createElement('div');
            document.body.appendChild(div);
            div.textContent = target.getAttribute('href').replace('mailto:', '');
            range.selectNodeContents(div);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            selection.removeAllRanges();
            document.body.removeChild(div);
            event.target.dataset.title = this.lang['copied to buffer'];
            this.showToolTip(event);
        });
        if (is_xs()) {
            addEvent(tooltips, 'touchend', event => this.showToolTip(event));
            if (root === this.root) {
                addEvent(window.document.body, 'touchend',
                    event => this.hideToolTip(event)
                );
            }
        } else {
            const hideOnOut = () => {
                tooltip.dataset.timeout = setTimeout(
                    () => this.hideToolTip(),
                    this.settings.transitions_base_time
                );
            };
            addEvent(tooltips, 'mouseout', hideOnOut);
            addEvent(tooltips, 'mouseover', event => this.showToolTip(event));
            addEvent(tooltip, 'mouseout', hideOnOut);
            if (root === this.root) {
                addEvent(window.document.body, 'click',
                    event => this.hideToolTip(event)
                );
            }
        }
    }

    hideToolTip(event) {
        const tooltip = this.root.querySelector('[data-bind="tooltip"]');
        if (!tooltip) {
            return
        }
        if (tooltip.dataset.timeout) {
            tooltip.dataset.timeout = '';
        }
        tooltip.style.cssText = '';
        tooltip.classList.remove('in');
        if (event && event.type !== 'touchend') {
            event.stopImmediatePropagation();
            event.stopPropagation();
        }
    }

    bindLinksDropdown(param_root) {
        const root = param_root || this.root;
        const dropdown_selector = this.dropdown_selector;
        addClickEvent(root, '[data-toggle="dropdown"]', function onClick(event) {
            event.preventDefault();
            event.stopPropagation();
            this.closest(dropdown_selector).classList.toggle('open');
        });
        if (!this.bindLinksDropdownCalled) {
            this.bindLinksDropdownCalled = true;
            addEvent(window.document.body, 'click', function onClick(event) {
                root.querySelectorAll(`${dropdown_selector}.open`)
                    .forEach(el => el.classList.remove('open'));
            });
        }
    }

    bindLinksCollapse(param_root) {
        const root = param_root || this.root;
        addClickEvent(root, '[data-toggle="collapse"]', function onClick(event) {
            event.preventDefault();
            const href = this.dataset.target || this.getAttribute('href');
            const obj = this.closest(href) || root.querySelector(href);
            if (obj) {
                obj.classList.toggle('in');
                event.stopPropagation();
            }
        });
    }

    openModal(href) {
        const obj = document.querySelector(href);
        if (obj) {
            obj.classList.add('in');
            document.body.classList.add('modal-open');
            return true;
        }
        return false;
    }

    closeModal() {
        if (this.dialogOnClose) {
            this.dialogOnClose();
            delete this.dialogOnClose;
        };

        const dialogs = document.querySelectorAll('.in[data-bind="modal"]');
        if (dialogs.length) {
            dialogs[dialogs.length -1].classList.remove('in');
        }

        if (!document.querySelector('.in[data-bind="modal"]')) {
            document.body.classList.remove('modal-open');
        }
    }

    bindLinksModals(param_root) {
        const root = param_root || this.root;
        const that = this;
        addClickEvent(root, '[data-toggle="modal"]', function onClick(event) {
            event.preventDefault();
            const href = this.dataset.target || this.getAttribute('href');
            that.openModal(href);
        });
        addClickEvent(root, '[data-dismiss="modal"], .modal-backdrop', event => {
            this.closeModal();
        });
    }

    bindKeyEvents() {
        addEvent(window, 'keydown', event => {
            if (!this.current_view || !this.current_view.shortcuts) {
                return;
            }
            this.current_view.shortcuts.forEach(check => {
                event.meta_or_ctrlKey = event.metaKey || event.ctrlKey;
                let status = true;
                ['which', 'key', 'metaKey', 'shiftKey', 'ctrlKey', 'meta_or_ctrlKey'].forEach((key) => {
                    if (check[key] && event[key] !== check[key]) {
                        status = false;
                    }
                });
                if (status) {
                    check.event(event);
                }
            });
        });
    }

    bindAddButton() {
        this.btn_main_add = document.querySelectorAll('[data-bind="btn-main-add"]');
        addEvent(this.btn_main_add, 'click', (event) => {
            event.preventDefault();
            if (this.current_view && typeof (this.current_view.add) === 'function') {
                this.current_view.add();
            }
        });
    }

    bindPopstate() {
        window.onpopstate = ({state}) => {
            const hash = window.location.hash + '';
            this.goToUri(state || hash.replace(/^#/, '') || this.start_uri);
        };
    }

    refresh(callback) {
        if (!this.current_view) {
            return
        }
        const uri = this.current_view.uri;
        const callbackController = typeof (callback) === 'function'
            ? callback
            : () => {
            };
        this.current_view.scrollTop = getScrollTop();
        this.current_view.get(true, () => {
            callbackController();
            this.current_view.render();
            this.current_view.show();
        }, callbackController);
        setTimeout(callbackController, this.settings.xhr_timeout);
    }

    bindClickToRefresh() {
        const refresh_btn = document.querySelector('[data-bind="btn-refresh"]');
        if (!refresh_btn) return;
        addEvent(refresh_btn, 'click', () => {
            let fired = false;
            const stopAnimation = () => {
                if (fired) {
                    refresh_btn.classList.remove('animate');
                } else {
                    fired = true;
                }
            };
            setTimeout(stopAnimation, this.settings.transitions_base_time * 10);
            refresh_btn.classList.add('animate');
            this.refresh(stopAnimation);
        });
    }

    bindPullBack() {
        const width = document.body.clientWidth;
        const start_treshold = width * 0.10;
        const end_treshold = width * 0.45;
        const touchMoveK = 0.45;

        const checkPullBackAvailability = event => {
            return (
                    event.touches && event.touches.length == 1
                    || event.type === 'touchend'
                ) && this.history.length > 1 && this.current_view
                && !this.pull_to_refresh
                && this.current_view.view;
        };

        let first_touch = null;
        addEvent(window.document.body, 'touchstart', event => {
            first_touch = null;
            if (!checkPullBackAvailability(event)) return;
            if (event.touches[0].clientX <= start_treshold) {
                first_touch = event.touches[0].clientX;
            }
        });
        addEvent(window.document.body, 'touchmove', event => {
            if (!checkPullBackAvailability(event)) return;
            if (
                !this.pull_to_back
                && first_touch !== null
                && event.touches[0].clientX > first_touch
            ) {
                this.pull_to_back = {
                    "start": event.touches[0].clientX
                };
                console.log(`pull_to_back start ${this.pull_to_back.start}`);
            }
            if (this.pull_to_back) {
                if (event.cancelable) event.preventDefault();
                this.pull_to_back.size =
                    event.touches[0].clientX - this.pull_to_back.start;
                this.current_view.view.style.marginLeft =
                    `${this.pull_to_back.size * touchMoveK}px`;
                if (
                    event.touches[0].clientX >= end_treshold
                    && !this.pull_to_back.fired
                ) {
                    console.log('pull_to_back fired');
                    this.pull_to_back.fired = true;
                }
                if (
                    this.pull_to_back.fired
                    && event.touches[0].clientX < end_treshold
                ) {
                    console.log('pull_to_back unfired');
                    this.pull_to_back.fired = false;
                }
            }
        });
        addEvent(window.document.body, 'touchend', event => {
            const animate = () => {
                this.current_view.view.style.marginLeft = '0px';
                this.pull_to_back = null;
            };
            if (this.pull_to_back) {
                console.log('pull_to_back end');
                if (this.pull_to_back.fired) {
                    setTimeout(
                        () => this.history_back(),
                        this.settings.transitions_base_time
                    );
                }
                animate();
            }
            first_touch = null;
        });
    }

    bindPullToRefresh() {
        const height_treshold = this.settings.pull_to_refresh_height || 130;
        const start_treshold = this.settings.pull_to_refresh_start || 10;
        const touchbar = window.document.querySelector('#pull-to-refresh');
        const k = 0.5; // pull down length to bar height
        if (!touchbar) {
            console.error('pull-to-refresh bar not found');
            return;
        }
        if (this.platform.id === 'ios') {
            touchbar.classList.add('ios');
        }
        const checkPullToRefreshAvailability = event => {
            return (
                    event.touches && event.touches.length == 1
                    || event.type === 'touchend'
                ) && this.current_view
                && !document.body.classList.contains('modal-open')
                && !this.current_view.noPullToRefresh
                && !this.pull_to_back;
        }
        let first_touch = null;
        addEvent(window.document.body, 'touchstart', event => {
            first_touch = null;
            if (!checkPullToRefreshAvailability(event)) return;
            let target = event.target;
            let limit = 100;
            do {
                if (target.scrollTop > start_treshold) {
                    console.log('Cancel pull_to_refresh');
                    return;
                }
                target = target.parentElement;
                limit--;
            } while (limit > 0 && target && target != window.document.body);

            if (getScrollTop() <= start_treshold) {
                first_touch = event.touches[0].clientY;
            }
        });
        addEvent(window.document.body, 'touchmove', event => {
            if (!checkPullToRefreshAvailability(event)) return;
            if (
                !this.pull_to_refresh
                && first_touch !== null
                && event.touches[0].clientY > first_touch
            ) {
                this.pull_to_refresh = {
                    "start": event.touches[0].clientY
                };
                touchbar.classList.remove('collapsing');
                console.log(`pull_to_refresh start ${this.pull_to_refresh.start}`);
            }
            ;
            if (this.pull_to_refresh) {
                if (event.cancelable) event.preventDefault();
                this.pull_to_refresh.size =
                    event.touches[0].clientY - this.pull_to_refresh.start;
                touchbar.style.height = `${this.pull_to_refresh.size * k}px`;
                if (
                    this.pull_to_refresh.size >= height_treshold
                    && !this.pull_to_refresh.fired
                ) {
                    console.log('pull_to_refresh fired');
                    this.pull_to_refresh.fired = true;
                    touchbar.classList.add('fired');
                }
                if (
                    this.pull_to_refresh.fired
                    && this.pull_to_refresh.size < height_treshold
                ) {
                    console.log('pull_to_refresh unfired');
                    this.pull_to_refresh.fired = false;
                    touchbar.classList.remove('fired');
                }
            }
            ;
        });
        addEvent(window.document.body, 'touchend', () => {
            const animate = () => {
                touchbar.classList.remove('fired');
                touchbar.classList.add('collapsing');
                touchbar.style.height = '0px';
                this.pull_to_refresh = null;
            }
            if (this.pull_to_refresh) {
                console.log('pull_to_refresh end');
                if (this.pull_to_refresh.fired) {
                    this.refresh(animate);
                } else {
                    animate();
                }
            }
            first_touch = null;
        });
    }

    history_back() {
        if (this.history.length <= 1) return false;
        this.goToUri({
            uri: this.history[this.history.length - 2],
            length: this.history.length - 1
        });
        return true;
    }

    processHistroy(state) {
        if (this.settings.dev) console.log('processHistroy', state);
        const hist = this.history;
        const view = this.getObject(state.uri || this.start_uri);
        const callNativeHistory = (hist, funcName) => {
            window.history[funcName](
                {uri: state.uri, length: hist.length},
                (view && view.title) || state.uri,
                `#${state.uri}`
            );
        };
        // proccessing backward navigation
        if (hist.length >= state.length) {
            hist.splice(state.length, hist.length - state.length);
            callNativeHistory(hist, 'replaceState');
            this.settings.views.classList.add('back');
            return;
        }
        this.settings.views.classList.remove('back');

        // checking if navigate the same view
        if (hist.length && hist[hist.length - 1] == state.uri) {
            return;
        }
        // processing forward navigation
        hist.push(state.uri);
        callNativeHistory(hist, 'pushState');
    }

    goToUri(param_state) {
        let state = param_state;
        const that = this;
        if (!state || !state.uri || !state.length) {
            state = {
                uri: window.location.hash.replace(/^#/, ''),
                length: this.history.length
            };
        }
        const view = this.getObject(state.uri);

        this.processHistroy(state);
        if (!view) {
            this.get(state.uri);
            return;
        }
        view.uri = state.uri;

        const cur_view = this.current_view;
        this.current_view = view;
        this.platform.onNavigate(this.current_view.uri);

        const new_slide = view.view;
        const old_slide = cur_view ? cur_view.view : null;
        if (cur_view && cur_view !== view) {
            cur_view.scrollTop = getScrollTop();
        }
        const hide = function onHide() {
            that.closeModal();
            cur_view.hide_timeout = setTimeout(() => {
                old_slide.classList.add('hidden');
                old_slide.classList.remove('out');
                if (cur_view) cur_view.onHide();
                old_slide.style.marginTop = '0px';
            }, that.settings.transitions_base_time);
            old_slide.style.marginTop = `${(view.scrollTop || 0) - (cur_view.scrollTop || 0)}px`;
            old_slide.classList.add('out');
            old_slide.classList.remove('in');
        };
        const show = function onShow() {
            new_slide.style.marginTop = '0px';
            new_slide.classList.remove('hidden');
            new_slide.classList.remove('out');
            if (view.hide_timeout) {
                clearTimeout(view.hide_timeout);
                delete view.hide_timeout;
            }
            ;
            setTimeout(() => {
                setScrollTop(view.scrollTop || 0);
                that.hideToolTip();
                new_slide.classList.add('in');
                setTimeout(
                    () => view.onShow(),
                    that.settings.transitions_base_time
                );
            }, 1);
        };
        document.querySelectorAll('header menu>li').forEach((item) => {
            const re = new RegExp(`^${item.dataset.url}`);
            toggleClass(item, 'active', state.uri.match(re));
        });

        if (old_slide && old_slide !== new_slide) {
            show();
            setTimeout(() => hide(), 1);
        } else {
            if (view.wildcard_uri) view.render();
            show();
        }

        // header
        document.title = new_slide.dataset.title + (this.settings.title ? ` - ${this.settings.title}` : '');
        if (view.btn_main_add) {
            this.btn_main_add.forEach((btn) => {
                btn.innerHTML = view.btn_main_add;
                btn.classList.remove('hidden');
                this.renderIcons(btn);
            });
        } else {
            this.btn_main_add.forEach((btn) => {
                btn.classList.add('hidden');
            });
        }
        this.loading(false);
    }

    showView(param_element) {
        let uri = param_element && param_element.uri;
        this.goToUri({uri, length: this.history.length + 1});
    }

    scrollTo(obj_or_top, bottom) {
        const hop = 1 / 50;
        const time = 350;
        const func = (t) => {
            return t * (2 - t)
        };
        let dist = 0;
        let obj;
        let scrollTop = getScrollTop();
        const top_shift = -this.settings.scroll_top_shift;
        const bottom_shift = this.settings.scroll_bottom_shift;
        if (typeof (obj_or_top) === 'string') {
            obj = (this.current_view.view
                && this.current_view.view.querySelector(selector))
                || window.document.querySelector(selector);
        } else if (typeof (obj_or_top) === 'number') {
            dist = obj_or_top - scrollTop;
            if (bottom) dist -= window.innerHeight;
        } else {
            obj = obj_or_top;
        }
        if (obj) {
            const rect = obj.getBoundingClientRect();
            if (
                (rect.top < top_shift || rect.top >= window.innerHeight * 0.4)
                && !bottom
            ) {
                dist = rect.top + top_shift;
            }
            if (
                (
                    rect.bottom > window.innerHeight - bottom_shift
                    || rect.bottom < window.innerHeight * 0.6
                ) && bottom
            ) {
                dist = rect.bottom - window.innerHeight - bottom_shift;
            }
        }
        ;
        if (dist === 0) return;

        const animate = (progress) => {
            setScrollTop(scrollTop + dist * func(progress));
            if (progress < 1) {
                setTimeout(() => {
                    animate(progress + hop)
                }, time * hop);
            }
        };
        animate(hop);
    }

    online(flag) {
        this.is_online = flag;
        toggleClass(window.document.body, 'offline', !flag);

        if (this.is_online) {
            this.offlineAlerted = false;
        } else {
            this.loading(false);
            this.alertOffline();
        }
    }

    alertOffline(force = false) {
        // only force alert now this.offlineAlerted policy disabled
        if (force) {
            this.alert(this.lang.offline_error);
            this.offlineAlerted = true;
        }
    }

    revalidating(flag) {
        this.is_revalidating = flag;
        toggleClass(window.document.body, 'background-refresh', flag);
    }

    ajax(options) {
        let headers = {};
        let data;
        const that = this;
        const xhr = new XMLHttpRequest();
        try {
            xhr.timeout = options.timeout || this.settings.xhr_timeout;
        } catch (err) {
            window.console.log('using default xhr timeout');
        }
        if (typeof (options.error) !== 'function') {
            options.error = (status, data) => {
                that.error({status: status, data});
            }
        }
        ;
        if (typeof (options.callback) !== 'function') {
            options.callback = () => {
            }
        }
        ;
        if (!options.method) options.method = 'GET';
        xhr.open(options.method, options.url, true);
        xhr.onreadystatechange = function onreadystatechange() {
            if (xhr.readyState === 4) {
                try {
                    xhr.responseJSON = JSON.parse(xhr.responseText);
                } catch (err) {
                }
                ;
                if (xhr.status >= 200 && xhr.status < 299) {
                    options.callback(xhr.responseJSON || xhr.responseText);
                } else if (xhr.status > 299) {
                    options.error(xhr.status,
                        xhr.responseJSON || xhr.responseText
                    );
                } else {
                    xhr.ontimeout();
                    return;
                }
                that.online(true);
            }
        };
        xhr.ontimeout = () => {
            this.online(false);
            options.error(0);
        };
        headers = Object.assign(deepcopy(that.default_headers), options.headers);
        Object.keys(headers).forEach((header) => {
            const val = headers[header];
            if (val !== null) {
                xhr.setRequestHeader(header, val);
            }
        });
        if (options.rawData !== undefined) {
            data = options.rawData;
        } else {
            data = options.data ? JSON.stringify(options.data) : null;
        }
        try {
            xhr.send(data);
        } catch (err) {
            options.error(0, {message_code: 'XHR', message: err});
        }
    }

    loading(flag) {
        if (this.is_revalidating && flag) return;
        this.is_loading = (typeof (flag) === 'undefined') ? !this.is_loading : flag;
        if (this.is_loading) {
            this.settings.views.classList.add('loading');
        } else {
            this.settings.views.classList.remove('loading');
        }
    }

    checkToken(token, success, error) {
        this.localStorage('token', token);
        this.token = token;

        window.console.log('app.checkToken', token);
        this.default_headers[this.settings.token_header] = token;
        this.getUserData(success, error, true);
    }

    authFailed(message, backurl) {
        const msg = `app.authFailed with token:'${this.token}' from: #${backurl}`;
        if (this.platform.name !== 'web') {
            this.reportBugSentry(msg);
        };
        window.console.log(msg);
        this.platform.logout();
    }

    reportBugSentry(message, extra = {}) {
        const sentry = this.Sentry;
        const userData = this.settings.user_profile;
        const checkSentryClient = sentry && sentry.getCurrentHub().getClient();
        if (checkSentryClient && checkSentryClient.getOptions()) {
            try {
                if (userData) {
                    const {
                        id, email, is_active, is_corp, is_paid, is_free
                    } = userData;
                    sentry.setUser({
                        id, email, is_active, is_corp, is_paid, is_free
                    });
                };
                Object.keys(extra).forEach(
                    key => sentry.setExtra(key, extra[key])
                );
                sentry.captureMessage(message);
            } catch (err) {
                console.error(err);
            }
        } else {
            console.error('Can\'t report bug without sentry');
        }
    }

    dialog(data) {
        const document = window.document;
        const view = document.createElement('div');
        data.id = data.id || `dialog-${Math.round(Math.random() * 100000)}`;
        if (document.getElementById(data.id)) {
            document.getElementById(data.id).remove();
        };
        view.innerHTML = this.Mustache.render(
            document.getElementById('modal').innerHTML,
            data
        );
        if (!data.buttons) data.buttons = [];
        data.buttons.forEach(btn => {
            addClickEvent(view, `[data-bind="${btn.name}"]`, btn.action);
        });
        this.dialogOnClose = data.onClose;
        this.bindLinksModals(view);
        if (data.subclass) {
            view.querySelector(`.${this.settings.cssPrefix.prefix}modal-dialog`)
                .classList.add(data.subclass);
        }
        document.body.appendChild(view.firstChild);
        this.openModal(`#${data.id}`);
    }

    dialogComponent(uri, componentClass, data = {}, parent = null) {
        if (!uri) {
            console.error(`dialogComponent have no uri`);
            return;
        }
        const dialogId = uri.replace(/\//g, '_');
        const component = this.initObject(uri, componentClass, data);
        component.parent = parent;
        const tmpl = document.getElementById(component.template);

        component.get(false, () => {
            this.dialog({
                id: dialogId,
                subclass: data.subclass,
                onClose: data.onClose,
                name: tmpl && tmpl.dataset.title,
                content: `<div class="obj-${componentClass} subcomponent" data-uri="${uri}"></div>`
            });
            component.view = document.getElementById(dialogId)
                .querySelector(`.obj-${componentClass}`);
            component.render();
        });
    }

    error(data = {}) {
        if (data.status === 0) {
            data.networkIssue = true;
        }
        this.platform.sendEvent('error_shown');
        this.dialogComponent(
            `error-${Math.round(Math.random() * 100000)}`, 'error', data
        );
    }
}
