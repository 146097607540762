import platform from './platformWeb';

const CORDOVA_SAVE_URI_RE = new RegExp(
    /^(threads|passengers|chats|user_settings|user)/
);

export default class platformApp extends platform {
    constructor(app) {
        super(app);
        this.is_mobile = true;
        this.name = 'cordova';
        this.id = (window.navigator.userAgent+'').match(/iPhone OS|iPad/)
            ? 'ios'
            : 'android';
        this.app = app;
        this.events = {};
    }
    on(eventType, callback) {
        if (!this.events[eventType]) this.events[eventType] = [];
        this.events[eventType].push(callback);
    }
    off(eventType, callback) {
        this.events[eventType].splice(
            this.events[eventType].findIndex(callback), 1
        );
    }
    callevent(eventType) {
        if (this.events[eventType]) {
            this.events[eventType].forEach(callback => callback());
        }
    }
    logout() {
        let id = window.setTimeout(() => {}, 0);
        while (id) {
            window.clearTimeout(id);
            id -= 1;
        }
        id = window.setInterval(() => {}, 0);
        while (id) {
            window.clearInterval(id);
            id -= 1;
        }
        this.app.dataStorage.erase(
            () => console.log('Data successfylly removed'),
            e => console.error(e)
        );
        // destroy all objects
        Object.keys(this.app.objects).forEach(url => {
            try {
                const o = this.app.objects[url];
                if (o.destroy) o.destroy();
            } catch (err) {}
        });
        // destroy page content
        document.body.innerHTML = '';
        window.document.write('');
        window.cordova.exec(
            () => {
                console.log('cordova logout success');
            },
            e => console.error(e),
            'LogoutPlugin',
            'logout'
        );
    }
    onKeyboardShow() {
        window.document.body.classList.add('keyboard');
        this.callevent('keyboardshow');
    }

    onKeyboardHide() {
        // iOS does't resize viewport on keyboard
        // no needs to call this event for iOS
        if (this.id === 'android') {
            window.document.body.classList.remove('keyboard');
            this.callevent('keyboardhide');
        }
    }

    get(uri) {
        const user_profile = this.app.settings.user_profile;
        if (!user_profile || !user_profile.id || uri === 'login/') {
            return 'login/';
        }
        if (!user_profile.has_user_seatmap_strategies) {
            return 'user/seat_preferences/';
        }
        if (!uri) {
            return this.app.start_uri;
        }
        return uri;
    }
    init() {
        this.app.start_uri = 'threads/';
        this.app.getUserData(() => {
            this.onReceivePush(null, result => {
                // if result true - do not navigate to start_uri, push did
                if (!result) {
                    // this.app.dataStorage.getStartURL(
                    //     uri => this.app.get(CORDOVA_SAVE_URI_RE.test(uri)
                    //         ? uri
                    //         : this.app.start_uri
                    //     ),
                    //     () => this.app.get(this.app.start_uri)
                    // )
                    this.app.get(this.app.start_uri);
                }
            });
        }, (userView, status) => userView.onNetworkError(status));
    }
    onReceivePush(data, callback) {
        console.log('Checking for push notification');
        if  (typeof(callback) !== 'function') callback = result => {};
        this.app.dataStorage.getNotification(data => {
            super.onReceivePush(data, callback)
        }, () => {
            console.log('No notification found');
            callback(false);
        })
    }
    showFile(id, url, ext, content_type, checkin_id, filetype) {
        const isBoarding = false;
        window.cordova.exec(
            () => {
                console.log('cordova showFile success');
            },
            e => console.error(e),
            'PersistentViewPlugin',
            'open',
            [
                parseInt(id), url, ext, content_type,
                checkin_id || 0,
                isBoarding
            ]
        );
        return false;
    }
    showBoardings(checkin_data) {
        const data = checkin_data.files.map( file => {
            return {
                id: file.id,
                ext: file.ext,
                mime: file.content_type,
                name: file.name,
                url: file.fileurl
            }
        });
        window.cordova.exec(
            () => {
                console.log('cordova boarding success');
                this.app.dataStorage.isBPUniq(
                    checkin_data.id,
                    () => this.sendEvent('boarding_pass_shown', {checkin_id})
                );
            },
            e => console.error(e),
            'BoardingPassesViewPlugin',
            'display',
            [
                JSON.stringify(data)
            ]
        );
        const isFree = !this.app.settings.user_profile
            || this.app.settings.user_profile.is_free;
        if (isFree) {
            setTimeout(() => this.showTipDialog(checkin_id), 5000)
        }
        else if (!isFree) {
            console.log('Not showing tip dialog for non-free users');
        }
        return false;
    }
    showPassengerStats(psg_id) {
        window.cordova.exec(
            () => {
                console.log('cordova passenger stats success');
                this.sendEvent('passeenger_stats_shown');
            },
            e => console.error(e),
            'MapPlugin',
            'open',
            [psg_id]
        );
        return false;
    }

    showTipDialog(checkin_id) {
        console.log('opening tip dialog');
        window.cordova.exec(
            () => console.log('cordova TipPlugin success'),
            e => console.error(e),
            'TipPlugin',
            'tip',
            checkin_id ? [checkin_id] : []
        );
        return false;
    }

    showRateDialog(metrika) {
        console.log('opening rate dialog');
        window.cordova.exec(
            () => console.log('cordova RatePlugin success'),
            e => console.error(e),
            'RatePlugin',
            'rate',
            [metrika]
        );
        return false;
    }

    openPaymentWindow(url) {
        url += `&lang=${this.app.settings.lang}&allow_dark=true`;
        console.log('opening PaymentWindow');
        window.cordova.exec(
            () => console.log('cordova ExternalWebPlugin success'),
            e => console.error(e),
            'ExternalWebPlugin',
            'open',
            [url]
        );
    }

    onNavigate(uri) {
        // save url only it starts with main menu
        if (CORDOVA_SAVE_URI_RE.test(uri)) {
            this.app.dataStorage.saveStartURL(uri);
        }
        const is_chat_open = uri.match(/^chats/) ? true : false;
        window.cordova.exec(
            () => {},
            e => console.error(e),
            'ChatPlugin',
             is_chat_open ? 'open' : 'close',
            []
        );
    }

    checkUploadFilesSupport() {
        console.log('opening PermissionCheckPlugin');
        window.cordova.exec(
            () => console.log('cordova PermissionCheckPlugin success'),
            e => console.error(e),
            'PermissionCheckPlugin',
            'camera',
            []
        );
    }

    buySubscription() {
        console.log('opening SubscriptionPlugin');
        window.cordova.exec(
            () => console.log('cordova SubscriptionPlugin success'),
            e => console.error(e),
            'SubscriptionPlugin',
            'buy',
            []
        );
    }

    buyCheckins() {
        console.log('opening buyCheckins');
        window.cordova.exec(
            () => console.log('cordova CheckInPlugin success'),
            e => alert(e),
            'CheckInPlugin',
            'buy',
            []
        );
    }

    sendEvent(eventName, params) {
        console.log(`sending Metrica event ${eventName}`);
        if (!params) { params = {}};
        params.uri = this.app.current_view.uri;
        params.className = this.app.current_view.className;
        window.cordova.exec(
            () => console.log('cordova MetricaPlugin success'),
            e => console.error(e),
            'MetricaPlugin',
            'log',
            [eventName, JSON.stringify(params)]
        );
    }

    setBadge(count) {
        console.log(`calling setBadge ${count}`);
        this.app.dataStorage.setBadge(
            count || 0,
            () => {
                window.cordova.exec(
                    () => console.log('cordova BadgePlugin success'),
                    e => console.error(e),
                    'BadgePlugin',
                    'update',
                    []
                );
            },
            () => console.error('Can\'t save badge to file')
        );
    }

    share(text, element) {
        console.log('opening SharePlugin');
        window.cordova.exec(
            () => console.log('cordova SharePlugin success'),
            e => console.error(e),
            'SharePlugin',
            'shareText',
            [text]
        );
    }

    close() {
        console.log('opening close');
        window.cordova.exec(
            () => console.log('close success'),
            e => console.error(e),
            'ClosePlugin',
            'close',
            []
        );
    }

    changeLang(lang) {
        location.href = `/${ lang === 'en' ? 'index' : lang }.html`
    }
}
