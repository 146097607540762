import configure from './config';
import './css/index.less';
// #if process.env.MOBILE_APP
import appCache from './appCacheCordova';
// #endif


// #if process.env.MOBILE_APP
function entry() {
    const languages = ['ru'];
    const [lang] =  window.navigator.language.split(/-/);
    const match = window.location.href.match(/(\w+)\.html/);
    const docLang = match ? match[1] : 'en';
    const scriptId = 'cordova-script';

    if (lang && docLang && lang != docLang) {
        if (languages.indexOf(lang)>-1 && !window.cordova_lang_redirect) {
            window.cordova_lang_redirect = true;
            console.log(`reloading lang ${docLang}->${lang}`);
            window.location.href = `./${lang}.html`;
            return;
        }
    }
    if (!window.cordova) {
        if (!window.document.getElementById(scriptId)) {
            console.log('No cordova plugin found. Injecting');
            var script = document.createElement('script');
            script.id = scriptId;
            script.src = window.navigator.platform.match(/iPhone|iPad/)
                ? './cordova.js'
                : './cordova-android.js';
            window.document.head.appendChild(script);
            window.cordova_try = 0;
        }
        else {
            console.log(`No cordova plugin wait ${window.cordova_try}`);
            window.cordova_try += 1;
        }
        setTimeout(entry, 500);
        return;
    }
    let calls = 0;
    const onReady = () => {
        if (calls) return;
        calls += 1;
        const fs = new appCache();
        fs.getToken(config => window._app = configure(config));
    }
    console.log('binding deviceready');
    document.addEventListener('deviceready', () => {
        console.log('deviceready');
        onReady()
    }, false);
    setTimeout(onReady, 3000);
}
// #endif

// #if !process.env.MOBILE_APP
// function entry() {
//     window.och_widget_profile = configure;
// }
// #endif

entry();
